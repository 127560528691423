<script setup>
import { ref, onMounted } from "vue";
import { useGlobalStore } from "../stores/global";
import { useRouter } from "vue-router";

const router = useRouter();
const globalStore = useGlobalStore();
const items = ref([
  {
    label: "Dashboard",
    icon: "pi pi-table",
    command: () => {
      router.push({ path: "/" });
    },
  },
  {
    label: "Calendario",
    icon: "pi pi-calendar",
    command: () => {
      router.push({ path: "/calendar" });
    },
  },
  {
    label: "Comercial",
    icon: "pi pi-wallet",
    command: () => {
      router.push({ path: "/comercial" });
    },
  },
  {
    label: "Contabilidad",
    icon: "pi pi-calculator",
    command: () => {
      router.push("/contabilidad");
    },
  },
  {
    label: "Logistica",
    icon: "pi pi-car",
    command: () => {
      router.push("/logistica");
    },
  },
  {
    label: "Talento Humano",
    icon: "pi pi-heart",
    command: () => {
      router.push("/talentohumano");
    },
  },
  {
    label: "Actas",
    icon: "pi pi-file",
    command: () => {
      router.push("/actas");
    },
  },
]);
</script>
<template>
  <div class="fixed h-screen overflow-auto text-white">
    <div
      class="fixed flex flex-col top-0 left-0 bg-orange-500 h-full border-r transtion duration-300"
      :class="globalStore.isExpanded ? 'w-64' : 'w-20'"
    >
      <div class="flex justify-between mr-5 mt-4">
        <div>
          <img
            v-if="globalStore.isExpanded"
            src="../assets/Logo-alfa-white.png"
            class="w-5 ml-5"
          />
        </div>
        <span
          :class="!globalStore.isExpanded && 'mt-3'"
          class="inline-flex justify-center items-center ml-4"
          @click="globalStore.toggleExpanded"
        >
          <i
            class="pi pi-angle-double-left cursor-pointer duration-300"
            :class="!globalStore.isExpanded && 'rotate-180 duration-300'"
            @click="toggleMenu"
          ></i>
        </span>
      </div>
      <div class="overflow-y-auto overflow-x-hidden flex-grow">
        <ul class="flex flex-col py-4 space-y-1">
          <li v-for="item in items" v-bind:key="item.label">
            <a
              @click="item.command"
              class="relative cursor-pointer flex flex-row items-center h-11 focus:outline-none hover:bg-gray-400 hover:rounded-l-lg text-white hover:text-orange-600 border-l-4 border-transparent hover:border-orange-500 pr-6"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <i :class="item.icon"></i>
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">{{
                item.label
              }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
