import { defineStore } from "pinia";

import { getWeek, getMonth } from "../utils/dateformatter"

import dayjs from "dayjs";
import "dayjs/locale/es";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);
dayjs.locale("es");

export const useGlobalStore = defineStore("global", {
    state: () => ({
        isLoading: false,
        isExpanded: true,
        currentWeek: null,
        isVisibleItemModal: false,
        selectedCot: null,
    }),
     actions: {
         toggleLoading() {
             this.isLoading = !this.isLoading;
         },
         toggleExpanded() {
             this.isExpanded = !this.isExpanded;
         },
         getCurrentWeek() {
           this.currentWeek = getWeek();
         },
         toggleModal() {
           this.isVisibleItemModal = !this.isVisibleItemModal;
         },
         setSelectedCot(cot) {
           this.selectedCot = cot;
         }
     }
    });
