<script setup>
import { ref, onBeforeMount, onMounted } from "vue";
import Chart from "primevue/chart";

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: () => [],
  },
  label: {
    type: String,
    required: false,
    default: () => "Saldo servicios",
  },
});

let dataValues = [];
let colors = ["rgb(255, 255, 255)", "rgb(255, 153, 0)", "rgb(140, 107, 64)"];

onMounted(() => {
  for (const i in props.data) {
    dataValues.push(props.data[i]);
  }
  for (let i = 3; i <= dataValues.length - 1; i++) {
    colors.push(`rgb(255, ${153 + i * 10}, ${10 * i})`);
  }
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
});

const chartData = ref();
const chartOptions = ref(null);

const setChartData = () => {
  return {
    labels: Object.keys(props.data),
    datasets: [
      {
        data: dataValues,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };
};

const setChartOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");

  return {
    plugins: {
      legend: {
        labels: {
          cutout: "60%",
          color: textColor,
        },
      },
    },
  };
};
</script>
<template>
  <Chart
    type="doughnut"
    :data="chartData"
    :options="chartOptions"
    class="h-20rem pt-4 pb-4"
  />
</template>
