import { getJogetApi } from "../Data/database";
import dayjs from "dayjs";
import "dayjs/locale/es";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);
dayjs.locale("es");

export const getContabilidadService = (base) => {
  let items = base.map((asamblea) => ({
    ...asamblea,
    c_eleccion_servicio:
      asamblea.c_eleccion_servicio?.label || asamblea.c_eleccion_servicio,
    color: asamblea.contabilidad_status,
    dateCreated: dayjs(asamblea.dateCreated).format("D [de] MMMM"),
    // c_fecha_primera_convocatoria: dayjs(
    //   asamblea.c_fecha_primera_convocatoria,
    //   "YYYY-MM-DD hh:mm a"
    // ).format("D [de] MMM [a las] hh:mm a"),
    // c_fecha_segunda_convocatoria: asamblea.c_fecha_segunda_convocatoria ? dayjs(
    //   asamblea.c_fecha_segunda_convocatoria,
    //   "YYYY-MM-DD hh:mm a"
    // ).format("D [de] MMM [a las] hh:mm a") : "",
    crud_primera_convocatoria: asamblea.c_fecha_primera_convocatoria,
  }));
  return items.filter((item) => item.c_cot_status === "approved");
};
