import dayjs from "dayjs";
import {
  getJogetApi,
  getInvoicesAlegraById,
  updateJogetApi,
} from "../Data/database";

/**
 * Se encarga de obtener información de listas de Joget.
 * @param {string} api - Api.
 * @param {string} data - Información.
 * @returns {any} Resultados de consulta.
 */
export const putItemService = async (api, data) => {
  if (!!data == false) return;
  return await updateJogetApi(api, data);
};

/**
 * Interfaz para obtener información base de items.
 */
export const getBaseService = async (
  url = import.meta.env.VITE_API_BASIC,
  year
) => {
  let basic = await getJogetApi(url + "?d-5356945-fn_year=" + year);

  let invoices = await getJogetApi(import.meta.env.VITE_API_INVOICES);
  let dropdowns = await getJogetApi(import.meta.env.VITE_API_DROPDOWNS);
  let signatures = await getJogetApi(import.meta.env.VITE_API_SIGNATURES);

  let items = await constructObject(
    basic.data,
    dropdowns.data,
    invoices.data,
    signatures.data
  );

  items.sort((a, b) => {
    let currentDate = new Date();
    let dateA = new Date(a.c_fecha_primera_convocatoria);
    let dateB = new Date(b.c_fecha_primera_convocatoria);

    // Check if 'a' has passed
    let aHasPassed = dateA < currentDate;
    // Check if 'b' has passed
    let bHasPassed = dateB < currentDate;

    // If both dates have passed or not passed, maintain original order
    if (aHasPassed === bHasPassed) {
      return 0;
    }

    // If 'a' has passed but 'b' hasn't, 'a' goes after 'b'
    // If 'b' has passed but 'a' hasn't, 'a' goes before 'b'
    return aHasPassed ? 1 : -1;
  });

  return { items: items, total: basic.size };
};

export const getFromAPI = async (url) => {
  return await getJogetApi(url);
};

const constructObject = (asambleas, dropdowns, invoices, signatures) => {
  return asambleas.map((asamblea) => {
    return {
      ...asamblea,
      logistica_status: asamblea.c_c_status_logistica || "bg-white",
      comercial_status:
        asamblea.c_cot_status === "approved" ? "bg-green-300" : "bg-white",
      contabilidad_status: asamblea.c_c_status_contabilidad || "bg-white",
      talento_status: asamblea.c_c_status_talento || "bg-white",
      c_id_cot: asamblea.c_id_cot.substring(4),
      c_agendado_po:
        signatures.find((sg) => sg.id === asamblea.c_agendado_po)?.sig_name ||
        "",
      c_advance_value: Number(asamblea.c_advance_value) || 0,
      c_nombre_ph_empresa: asamblea.c_nombre_ph_empresa.toUpperCase(),
      version: getVersionAsamblea(asamblea.c_eleccion_servicio),
      c_service_tags: !!asamblea.c_service_tags
        ? asamblea.c_service_tags
            .split(";")
            .map((tag) => dropdowns.find((t) => t.value === tag))
        : [],
      usuarios_reales: asamblea.c_participants_number || "Verificar en Sistevot",
      invoices: formatInvoices(asamblea, invoices),
      c_advance_status:
        dropdowns.find(
          (dp) =>
            dp.grouping === "advance_status" &&
            dp.value === asamblea.c_advance_status
        ) || "No",
      // TODO:
      // Convertir fecha en objeto Date
      c_fecha_primera_convocatoria:
        asamblea.c_fecha_primera_convocatoria
          .substring(0, 10)
          .split("-")
          .reverse()
          .join("-") + asamblea.c_fecha_primera_convocatoria.substring(10),
      c_fecha_segunda_convocatoria:
        asamblea.c_fecha_segunda_convocatoria
          .substring(0, 10)
          .split("-")
          .reverse()
          .join("-") + asamblea.c_fecha_segunda_convocatoria.substring(10),
      c_hora_finalizacion_asamblea:
        asamblea.c_hora_finalizacion_asamblea
          .substring(0, 10)
          .split("-")
          .reverse()
          .join("-") + asamblea.c_hora_finalizacion_asamblea.substring(10) ||
        "",
      c_total_final: "$" + (asamblea.c_total_final || 0),
      informes: formatReports(asamblea, signatures),
      advances: !!asamblea.c_advance_value ? "RESTA" : "PENDIENTE",
      color: asamblea.c_color || "bg-white",
      c_eleccion_servicio: mapService(
        getVersionAsamblea(asamblea.c_eleccion_servicio),
        asamblea,
        dropdowns
      ),
      c_db_status: !!asamblea.c_participants_number ? "Subido" : "No subido",
    };
  });
};

const mapService = (version, asamblea, dropdowns) => {
  if (version === "v1") {
    return dropdowns.find(
      (dp) =>
        dp.grouping === "tag_service_type" &&
        dp.value === asamblea.c_eleccion_servicio
    );
  } else if (version === "v2") {
    return dropdowns.find(
      (dp) =>
        dp.grouping === "service_type" &&
        dp.value === asamblea.c_eleccion_servicio
    );
  }
};

const formatInvoices = (asamblea, invoices) => {
  let invoicesAsamblea = invoices.filter(
    (inv) => inv.id_cot === asamblea.c_id_cot
  );
  invoicesAsamblea = invoicesAsamblea.map((invoice) => ({
    ...invoice,
    status: formatPaymentStatus(invoice),
    refund: invoice.refund === "1",
    voided: invoice.voided === "1",
  }));
  if (invoicesAsamblea.length > 0) return invoicesAsamblea;
  else return [];
};

export const formatPaymentStatus = (invoice) => {
  if (invoice.voided === "1") return "ANULADO";
  else if (invoice.status === "closed") return "PAGO";
  else if (invoice.status === "open")
    return invoice.totalPaid === 0 ? "PENDIENTE" : "RESTA";
  else return "PENDIENTE";
};

const formatReports = (asamblea, signatures) => {
  return {
    c_fecha_envio:
      asamblea.c_fecha_envio.substring(0, 10).split("-").reverse().join("-") +
      asamblea.c_fecha_envio.substring(10),
    c_correo_envia_informes: asamblea.c_correo_envia_informes,
    c_enviado_por:
      signatures.find((sg) => sg.id === asamblea.c_enviado_por) || "",
    c_revisado_por:
      signatures.find((sg) => sg.id === asamblea.c_revisado_por) || "",
    c_status_to_upload: asamblea.c_status_to_upload,
  };
};

const getVersionAsamblea = (eleccionservicio) => {
  const Oldservices = [
    "asamblea_virtual",
    "asamblea_presencial",
    "asamblea_mixta",
    "solo_sonido_completo",
    "relatoria_acta",
    "indumentaria",
    "navidad",
    "videobeam_pantalla",
    "solo_videobeam",
    "asistencia+votación",
    "fiestas_halloween",
    "asamblea_flexible",
  ];
  if (Oldservices.includes(eleccionservicio)) return "v1";
  else return "v2";
};

const usersValidation = (asamblea) => {
  console.log(asamblea.c_registerdb);
  if (!!asamblea.c_registerdb) {
    return asamblea.c_registerdb;
  } else if (!!asamblea.c_field11) {
    let date = dayjs(
      asamblea.c_fecha_primera_convocatoria
        .substring(0, 10)
        .split("-")
        .reverse()
        .join("-")
    );

    let jsonFields = JSON.parse(asamblea.c_field11);
    let entries = Object.entries(jsonFields);
    entries.reverse();

    let selectedDb = entries.find((user) => {
      console.log(user);
      return dayjs(user.fecha).isSame(date, "day");
    });

    if (!!selectedDb) return selectedDb.registros;
    else return "No se pudo determinar, ingresar manualmente";
  }
};
