import { getJogetApi } from "../Data/database";
import dayjs from "dayjs";
import "dayjs/locale/es";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);
dayjs.locale("es");

export const getActasService = async () => {
  let actas = await getJogetApi(import.meta.env.VITE_LIST_ACTAS);
  return mappinActas(actas.data);
};

const mappinActas = (asambleas) => {
  return asambleas.map((asamblea) => ({
    ...asamblea,
    c_id_cot: asamblea.id.substring(4),
    c_nombre_ph_empresa: asamblea["sol_cot_client.nombre_ph_empresa"],
    c_fecha_primera_convocatoria:
      asamblea["sol_cot_service.fecha_primera_convocatoria"],
  }));
};
