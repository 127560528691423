import { toRaw } from "vue";
import dashboardYearData from "../Data/real/dashboardYearData.json";

export const calculateDataDashboard = (items, totalItems) => {
  if (toRaw(items).length === 0)
    return {
      totalInvoiced: 0,
      totalAdvances: 0,
      mostPopularService: "",
      totalLastYear: 0,
      growthPercentageInvoiced: 0,
      growthPercentageAdvances: 0,
      paymentStatus: {},
      reportsStatus: {},
      totalItems: 0,
    };

  // let year = items[0].c_fecha_primera_convocatoria.substring(6, 10)
  let year = items[0].c_fecha_primera_convocatoria.substring(0, 4);

  let totalInvoiced = totalFacturado(items, year);
  let totalAdvances = totalAnticipos(items, year);
  let mostPopularService = getMostPopularService(items, year);
  let totalLastYear = totalFacturadoByMonth(items, year);
  let paymentStatus = getPaymentStatus(items, year);
  let reportsStatus = getReportsStatus(items, year);

  let growthPercentageInvoiced = parseFloat(
    (
      ((totalInvoiced - totalLastYear.totalLastYear) /
        totalLastYear.totalLastYear) *
      100
    ).toFixed(2)
  );

  let growthPercentageAdvances = parseFloat(
    (
      ((totalAdvances - totalLastYear.totalAdvancesLastYear) /
        totalLastYear.totalAdvancesLastYear) *
      100
    ).toFixed(2)
  );
  growthPercentageAdvances =
    growthPercentageAdvances === Infinity ? 100 : growthPercentageAdvances;
  let totalWaiting = 0;
  items
    .filter((item) => item.c_cot_status === "waiting")
    .forEach((item) => {
      totalWaiting += Number(item.c_total_final?.substring(1)) || 0;
    });
  return {
    totalInvoiced,
    totalAdvances,
    mostPopularService,
    totalLastYear,
    growthPercentageInvoiced,
    growthPercentageAdvances,
    paymentStatus,
    reportsStatus,
    totalWaiting,
    totalItems: totalItems,
  };
};

const totalFacturadoByMonth = (items, year) => {
  if (
    dashboardYearData[year] !== undefined &&
    dashboardYearData[Number(year - 1)] !== undefined
  )
    return {
      totalLastYear: dashboardYearData[Number(year - 1)].totalInvoiced,
      totalAdvancesLastYear: dashboardYearData[Number(year - 1)].totalAdvances,
    };

  let totalLastYear = 0;
  let totalAdvancesLastYear = 0;
  let months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const monthsDashboard =
    dashboardYearData[new Date().getFullYear() - 1].months;

  for (const month in monthsDashboard) {
    if (monthsDashboard.hasOwnProperty(month)) {
      const details = monthsDashboard[month];
      if (months.indexOf(details.month) <= new Date().getMonth()) {
        totalLastYear += details.totalInvoiced;
        totalAdvancesLastYear += details.totalAdvances;
      }
    }
  }

  return {
    totalLastYear: parseFloat(totalLastYear.toFixed(2)),
    totalAdvancesLastYear: parseFloat(totalAdvancesLastYear.toFixed(2)),
  };
};

const totalFacturado = (items, year) => {
  if (dashboardYearData[year] !== undefined)
    return dashboardYearData[year].totalInvoiced;

  let total = 0;
  let totalWaiting = 0;
  items
    .filter((item) => item.c_cot_status === "approved")
    .forEach((item) => {
      total += Number(item.c_total_final?.substring(1)) || 0;
    });

  return parseFloat(total.toFixed(2));
};

const totalAnticipos = (items, year) => {
  if (dashboardYearData[year] !== undefined)
    return dashboardYearData[year].totalAdvances;

  let total = 0;
  items.forEach((item) => {
    total += item.c_advance_value;
  });
  return parseFloat(total.toFixed(2));
};

const getMostPopularService = (items, year) => {
  if (dashboardYearData[year] !== undefined)
    return dashboardYearData[year].mostPopularService.service;

  let services = [];
  items.forEach((item) => {
    item.version === "v2"
      ? item.c_service_tags.forEach((service) => services.push(service.label))
      : services.push(item.c_eleccion_servicio.label);
  });
  const counts = {};
  services.forEach((x) => {
    counts[x] = (counts[x] || 0) + 1;
  });

  let max = Object.keys(counts).reduce((a, b) =>
    counts[a] > counts[b] ? a : b
  );
  return max;
};

const getPaymentStatus = (items) => {
  let status = [];
  items.forEach((item) => {
    item.invoices.forEach((invoice) => status.push(invoice.status));
  });
  const counts = {};
  status.forEach((x) => {
    counts[x] = (counts[x] || 0) + 1;
  });
  return counts;
};

const getReportsStatus = (items) => {
  let status = [];
  items.forEach((item) => {
    status.push(item.informes.c_status_to_upload.toLowerCase());
  });
  const counts = {};
  status.forEach((x) => {
    counts[x] = (counts[x] || 0) + 1;
  });

  delete counts[""];

  if (counts.lenght < 10) {
    for (const key in counts) {
      if (counts.hasOwnProperty(key)) {
        const element = counts[key];
        if (element < 10) {
          counts["Otros"] = (counts["Otros"] || 0) + counts[key];
          delete counts[key];
        }
      }
    }
  }

  return counts;
};
