import { getJogetApi } from "../Data/database";
import dayjs from "dayjs";

export const getTalentoService = async (base) => {
  let operarios = await getJogetApi(
    import.meta.env.VITE_LIST_OPERARIOS_AGENDADOS
  );
  let items = mappingTalento(base, operarios.data);
  return items.filter((item) => item.c_cot_status === "approved");
};
const mappingTalento = (asambleas, operarios) => {
  return asambleas.map((asamblea) => {
    let opera = operarios.filter(
      (op) => op.c_cot_number === "COT-" + asamblea.c_id_cot
    );
    return {
      ...asamblea,
      color: asamblea.talento_status,
      c_eleccion_servicio:
        asamblea.c_eleccion_servicio?.label || asamblea.c_eleccion_servicio,
      dateCreated: dayjs(asamblea.dateCreated).format("D [de] MMMM"),
      // c_fecha_primera_convocatoria: dayjs(
      //   asamblea.c_fecha_primera_convocatoria,
      //   "YYYY-MM-DD hh:mm a"
      // ).format("D [de] MMM [a las] hh:mm a"),
      // c_fecha_segunda_convocatoria: asamblea.c_fecha_segunda_convocatoria
      //   ? dayjs(
      //       asamblea.c_fecha_segunda_convocatoria,
      //       "YYYY-MM-DD hh:mm a"
      //     ).format("D [de] MMM [a las] hh:mm a")
      //   : "",
      crud_primera_convocatoria: asamblea.c_fecha_primera_convocatoria,
      operarios_agendados: opera,
      operarios_cantidad: mapUsuariosNecesitados(asamblea),
      hora_extra: !!mapHorasExtras(asamblea) ? mapHorasExtras(asamblea) : "",
      c_hora_finalizacion_asamblea: !!asamblea.c_hora_finalizacion_asamblea
        ? dayjs(asamblea.c_hora_finalizacion_asamblea).format("D MMM hh:mm a")
        : "",
    };
  });
};

const mapHorasExtras = (asamblea) => {
  if (!!!asamblea.c_hora_finalizacion_asamblea) return "";
  let totalMinutes = dayjs(asamblea.c_hora_finalizacion_asamblea).diff(
    dayjs(asamblea.c_fecha_primera_convocatoria),
    "minutes"
  );
  let hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;
  return `${hours} horas y ${minutes} minutos`;
};

const mapUsuariosNecesitados = (asamblea) => {
  let operarios = [];
  if (!!asamblea.c_conductor_quantity)
    operarios.push({
      type: "Conductor",
      quantity: asamblea.c_conductor_quantity,
    });
  if (!!asamblea.c_acta_quantity)
    operarios.push({ type: "Acta", quantity: asamblea.c_acta_quantity });
  if (!!asamblea.c_virtual_qnty)
    operarios.push({ type: "Virtual", quantity: asamblea.c_virtual_qnty });
  if (!!asamblea.c_conexion_qnty)
    operarios.push({ type: "Conexión", quantity: asamblea.c_conexion_qnty });
  if (!!asamblea.c_sonido_qnty)
    operarios.push({ type: "Sonido", quantity: asamblea.c_sonido_qnty });
  if (!!asamblea.c_registro_qnty)
    operarios.push({ type: "Registro", quantity: asamblea.c_registro_qnty });
  return operarios;
};
