import { defineStore } from "pinia";
import {
  getBaseService,
  putItemService,
  getFromAPI,
  formatPaymentStatus,
} from "../services/common";
import { getComercialService } from "../services/comercial";
import { getLogisticaService } from "../services/logistica";
import { getTalentoService } from "../services/talento";
import { getActasService } from "../services/actas";
import { getWeek, getMonth } from "../utils/dateformatter";
import { getCalendarService } from "../services/calendar";
import { calculateDataDashboard } from "../services/dashboard";
import { getInvoicesAlegraById } from "../Data/database";
import dayjs from "dayjs";
import cotsinfo from "../Data/mock/common.json";
import { getContabilidadService } from "../services/contabilidad";

// TODO:
// 1. Crear una store que almacene todo independiente de año para calendario.
export const useCotsStore = defineStore("cots", {
  state: () => ({
    totalItems: 0,
    base: [],
    comercial: [],
    contabilidad: [],
    logistica: [],
    talento: [],
    dashboard: [],
    dropdowns: [],
    lastItem: {},
    calendar: [],
    currentWeek: [],
    filteredCalendar: [],
    signatures: [],
    actas: [],
  }),
  actions: {
    async getApiByField(url) {
      return await getFromAPI(url);
    },
    async getBasicData(year) {
      // this.base = cotsinfo;
      // this.totalItems = cotsinfo.length;
      let response = await getBaseService(import.meta.env.VITE_API_BASIC, year);
      this.base = response.items;
      this.totalItems = response.total;
      this.lastItem = this.base[this.base.length - 1];
    },
    async getBasicDataRange(from, to, pageSize = 35, year) {
      let url = `${import.meta.env.VITE_API_BASIC_RANGE}?${
        import.meta.env.VITE_API_BASIC_FROM_RANGE
      }=${from}&${
        import.meta.env.VITE_API_BASIC_TO_RANGE
      }=${to}&pageSize=${pageSize}`;

      let response = await getBaseService(url, year);
      this.base = [...this.base, ...response.items];
      this.totalItems += response.total;
      this.lastItem = this.base[this.base.length - 1];
    },
    getComercialData() {
      this.comercial = getComercialService(this.base);
      // this.comercial = this.base;
    },
    getContabilidadData() {
      this.contabilidad = getContabilidadService(this.base);
    },
    async getLogisticaData() {
      this.logistica = await getLogisticaService(this.base);
      // this.logistica = this.base
    },
    async getTalentoData() {
      this.talento = await getTalentoService(this.base);
      // this.talento = this.base;
    },
    async getEquiposData(date) {
      return await getFromAPI(
        import.meta.env.VITE_LIST_EQUIPOS_DISPONIBLES + "?fecha=" + date
      );
    },
    async getActasData() {
      this.actas = await getActasService();
    },
    updateCotStore(data) {
      let index = this.base.findIndex((item) => item.id === data.id);
      index !== -1 ? (this.base[index] = data) : this.base.push(data);

      index = this.comercial.findIndex((item) => item.id === data.id);
      index !== -1 ? (this.comercial[index] = data) : this.comercial.push(data);

      index = this.contabilidad.findIndex((item) => item.id === data.id);
      index !== -1
        ? (this.contabilidad[index] = data)
        : this.contabilidad.push(data);

      index = this.logistica.findIndex((item) => item.id === data.id);
      index !== -1 ? (this.logistica[index] = data) : this.logistica.push(data);

      index = this.talento.findIndex((item) => item.id === data.id);
      index !== -1 ? (this.talento[index] = data) : this.talento.push(data);

      index = this.dashboard.findIndex((item) => item.id === data.id);
      index !== -1 ? (this.dashboard[index] = data) : this.dashboard.push(data);
    },
    getCalendar(operation, filters = []) {
      if (this.base.length === 0) return;
      let lastDate = this.lastItem.c_fecha_primera_convocatoria;
      if (this.currentWeek.length === 0) this.currentWeek = getWeek();
      if (operation === "s")
        this.currentWeek = this.currentWeek.map((cw) =>
          dayjs(cw).subtract(7, "day").format("YYYY-MM-DD")
        );

      if (operation === "a")
        this.currentWeek = this.currentWeek.map((cw) =>
          dayjs(cw).add(7, "day").format("YYYY-MM-DD")
        );

      // if (dayjs(this.currentWeek[0]).isBefore(lastDate)) {
      //   this.getBasicDataRange(
      //     dayjs(lastDate).subtract(35, "day").format("DD-MM-YYYY"),
      //     dayjs(lastDate).format("DD-MM-YYYY")
      //   );
      // }

      this.calendar = getCalendarService(this.base, this.currentWeek);

      if (filters.length > 0) {
        return this.getFilteredAsambleas(this.calendar, filters);
      }
    },
    getFilteredAsambleas(list, filters) {
      const itemsCopy = JSON.parse(JSON.stringify(list));

      for (let index = 0; index < 7; index++) {
        const element = itemsCopy[index];

        element.items = element.items.filter((item) => {
          let eleccionServicioFilter =
            filters.findIndex((el) => el.grouping === "service_type") === -1
              ? true
              : filters.find(
                  (el) => el.value === item.c_eleccion_servicio.value
                );

          let tagFilter =
            filters.findIndex((el) => el.grouping === "tag_service_type") === -1
              ? true
              : item.c_service_tags.some((tag) =>
                  filters.find((el) => el.value === tag.value)
                );

          let statusFilter =
            filters.findIndex((el) => el.grouping === "c_cot_status") === -1
              ? true
              : filters.find((el) => el.value === item.c_cot_status);

          return eleccionServicioFilter && tagFilter && statusFilter;
        });
      }

      return itemsCopy;
    },
    async putItem(api, data) {
      await putItemService(api, data);
    },
    async updateInvoices(invoices) {
      let updatedInvoices = [];
      invoices.forEach(async (invoice) => {
        let invoiceAlegra = await getInvoicesAlegraById(invoice.id_invoice);

        let updatedInvoice = {
          id: invoice.id,
          number_invoice: invoiceAlegra.numberTemplate.fullNumber,
          total: invoiceAlegra.total,
          paid: invoiceAlegra.totalPaid,
          created: invoiceAlegra.date,
          status: invoiceAlegra.status,
        };

        await this.putItem(
          import.meta.env.VITE_API_INVOICES_CREATE,
          updatedInvoice
        );

        updatedInvoices.push(updatedInvoice);
      });

      updatedInvoices = updatedInvoices.map((invoice) => {
        return {
          ...invoice,
          status: formatPaymentStatus(invoice.status),
        };
      });

      return updatedInvoices;
    },
    getDataDashboard() {
      this.dashboard = calculateDataDashboard(this.base, this.totalItems);
    },
    async getDropdowns() {
      let dropdowns = await getFromAPI(
        import.meta.env.VITE_API_DROPDOWNS + "?pageSize=150"
      );
      this.dropdowns = dropdowns.data;
    },
    async getSignatures() {
      let signatures = await getFromAPI(
        import.meta.env.VITE_API_SIGNATURES + "?pageSize=150"
      );
      this.signatures = signatures.data;
    },
  },
  getters: {},
});
