<script setup>
import { ref, onMounted } from "vue";
import { useCotsStore } from "./stores/cots";
import { useGlobalStore } from "./stores/global";
import ProgressSpinner from "primevue/progressspinner";
import Sidebar from "./components/Sidebar.vue";
import Dropdown from "primevue/dropdown";

const yearsWithSupport = ["2022", "2023", "2024"];
const selectedYear = ref(new Date().getFullYear().toString());
const cotStore = useCotsStore();
const globalStore = useGlobalStore();

onMounted(async () => {
  globalStore.toggleLoading();
  try {
    if (cotStore.base.length === 0)
      await cotStore.getBasicData(selectedYear.value);
    if (cotStore.signatures.length === 0) await cotStore.getSignatures();
  } finally {
    globalStore.toggleLoading();
  }
});

const onYearChange = async (year) => {
  globalStore.toggleLoading();
  try {
    await cotStore.getBasicData(year.value);
  } finally {
    globalStore.toggleLoading();
  }
};
</script>
<template>
  <div
    class="absolute surface-overlay top-0 left-0 h-full w-full flex justify-content-center items-center"
    style="z-index: 1001"
    v-if="globalStore.isLoading"
  >
    <ProgressSpinner
      v-show="globalStore.isLoading"
      style="width: 50px; height: 50px"
      strokeWidth="8"
      animationDuration=".8s"
    />
  </div>
  <div v-if="!globalStore.isLoading" class="flex">
    <Sidebar></Sidebar>
    <div
      :class="globalStore.isExpanded ? 'ml-64' : 'ml-20'"
      class="w-full h-screen duration-300 overflow-auto"
    >
      <Dropdown
        v-model="selectedYear"
        :options="yearsWithSupport"
        @change="onYearChange($event)"
        class="absolute z-2 right-0 mr-10 mt-3"
      />

      <router-view></router-view>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
