<script setup>
import { ref, onBeforeMount, onMounted } from "vue";
import Chart from "primevue/chart";

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: () => [],
  },
  label: {
    type: String,
    required: false,
    default: () => "Saldo servicios",
  },
});

let dataValues = [] 

onMounted(() => {
  for (const i in props.data) { dataValues.push(props.data[i])}
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
});

const chartData = ref();
const chartOptions = ref();

const setChartData = () => {
  return {
    labels: Object.keys(props.data),
    datasets: [
      {
        label: props.label,
        /* TODO:

      Change the data to the real data from the Dashboard service.

      */
        data: dataValues,
        backgroundColor: [
          "rgb(255, 202, 128)",
          "rgb(255, 202, 128)",
          "rgb(255, 202, 128)",
        ],
        borderColor: [
          "rgb(255, 153, 0)",
          "rgb(255, 153, 0)",
          "rgb(255, 153, 0)",
        ],
        borderWidth: 1,
      },
    ],
  };
};
const setChartOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

  return {
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
  };
};
</script>

<template>
  <Chart
    type="bar"
    :data="chartData"
    :options="chartOptions"
    class="h-20rem p-2"
  />
</template>
