import { getJogetApi } from "../Data/database";
import dayjs from "dayjs";

export const getLogisticaService = async (base) => {
  let reservas = await getJogetApi(import.meta.env.VITE_API_RESERVA_EQUIPOS);
  let items = mappinLogistica(base, reservas.data);
  return items.filter((item) => item.c_cot_status === "approved");
};

const mappinLogistica = (asambleas, reservas) => {
  return asambleas.map((asamblea) => ({
    ...asamblea,
    reservas_info: reservas.find((rs) => rs.c_cotizacion === asamblea.c_id_cot),
    // c_fecha_primera_convocatoria: dayjs(
    //   asamblea.c_fecha_primera_convocatoria,
    //   "YYYY-MM-DD hh:mm a"
    // ).format("D [de] MMM [a las] hh:mm a"),
    // c_fecha_segunda_convocatoria: asamblea.c_fecha_segunda_convocatoria
    //   ? dayjs(
    //       asamblea.c_fecha_segunda_convocatoria,
    //       "YYYY-MM-DD hh:mm a"
    //     ).format("D [de] MMM [a las] hh:mm a")
    //   : "",
    color: asamblea.logistica_status,
  }));
};
