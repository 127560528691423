import dayjs from "dayjs";
import "dayjs/locale/es";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);
dayjs.locale("es");

export const getCalendarService = (list, currentWeek, equipos) => {
  let asambleas = currentWeek.map((cw) => {
    return {
      day: cw,
      items: [],
    };
  });

  return mapAsambleas(list, asambleas);
};

const mapAsambleas = (list, asambleasInWeek) => {
  for (let index = 0; index < list.length; index++) {
    let a = list[index].c_fecha_primera_convocatoria.substring(0, 10);
    for (let j = 0; j < 7; j++) {
      if (a === asambleasInWeek[j].day) {
        asambleasInWeek[j].items.push({
          ...list[index],
          c_fecha_primera_convocatoria:
            a + list[index].c_fecha_primera_convocatoria.substring(10),
        });
      }
    }
  }

  return asambleasInWeek;
};
