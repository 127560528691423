// Vue
import { createApp } from 'vue'
import App from './App.vue'
// Prime Vue
import router from './router';
import PrimeVue from 'primevue/config'

// Font awesome
import { library } from "@fortawesome/fontawesome-svg-core"
import { faWallet, faCircleExclamation, faMoneyBills } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Tag from 'primevue/tag';

// Bootstrap
// import "bootstrap/dist/css/bootstrap.min.css"
import "../src/assets/css/Style.scss"

// Pinia
import { createPinia } from 'pinia'

library.add(faWallet)
library.add(faMoneyBills)
library.add(faCircleExclamation)

createApp(App)
    .use(PrimeVue, {
        zIndex: {
            modal: 1100,        //dialog, sidebar
            overlay: 1000,      //dropdown, overlaypanel
            menu: 1000,         //overlay menus
            tooltip: 1100       //tooltip
        }
    })
    .use(createPinia())
    .use(router)
    .component('DataTable', DataTable)
    .component('Column', Column)
    .component('Button', Button)
    .component('InputText', InputText)
    .component('Tag', Tag)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')