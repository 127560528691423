<script setup>
import { ref, onBeforeMount, onMounted } from "vue";
import DashboardWidget from "../components/DashboardWidget.vue";

import { randomMotivationalPhrase } from "../utils/randomMotivationalPhrase";

import BarChart from "../components/BarChart.vue";
import DonutChart from "../components/DonutChart.vue";

import { useCotsStore } from "../stores/cots";
import { useGlobalStore } from "../stores/global";

const cotStore = useCotsStore();
const globalStore = useGlobalStore();

let randomPhrase = ref(randomMotivationalPhrase());

onBeforeMount(() => {
  try {
    globalStore.toggleLoading();
    cotStore.getDataDashboard();
  } finally {
    globalStore.toggleLoading();
  }
});
</script>
<template>
  <div class="p-2">
    <div class="text-5xl text-gray-800 mt-4 font-bold ml-4 mb-2">
      Bienvenido
    </div>
    <div class="text-xs italic text-gray-500 ml-4">
      {{ randomPhrase.phrase }}
    </div>
    <div class="text-xs italic font-medium ml-4 mb-4">
      - {{ randomPhrase.author }}
    </div>

    <div class="flex p-2 w-full justify-between">
      <DashboardWidget
        class="w-full"
        icon="pi pi-money-bill"
        title="Facturado aprobado"
        :amount="cotStore.dashboard.totalInvoiced"
        :percentage="cotStore.dashboard.growthPercentageInvoiced"
      ></DashboardWidget>
      <DashboardWidget
        class="w-full"
        icon="pi pi-money-bill"
        title="Facturado en espera"
        :amount="cotStore.dashboard.totalWaiting"
      ></DashboardWidget>
      <DashboardWidget
        class="w-full"
        icon="pi pi-shopping-bag"
        title="Total anticipos"
        :amount="cotStore.dashboard.totalAdvances"
        :percentage="cotStore.dashboard.growthPercentageAdvances"
      ></DashboardWidget>
      <DashboardWidget
        class="w-full"
        icon="pi pi-star-fill"
        title="Servicio más pedido"
        :amount="cotStore.dashboard.mostPopularService"
      ></DashboardWidget>
      <div class="w-3 ml-5 h-full">
        <a href="https://app.alegra.com/" target="_blank">
          <Button class="block hover:bg-[#00d6bc] mb-2 w-full bg-[#00d6bc]">
            <template #icon>
              <img
                class="absolute"
                width="25"
                src="../assets/logoalegra.png"
              />
            </template>
          </Button>
        </a>
        <a href="https://joget.clasealpha.com:8443/jw" target="_blank">
          <Button class="block hover:bg-[#19996f] mb-2 w-full bg-[#19996f]">
            <template #icon>
              <img
                class="absolute"
                width="25"
                src="../assets/logojogetdxwhite.png"
              />
            </template>
          </Button>
        </a>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-4 p-4">
      <div class="bg-gray-100 col-span-2 shadow rounded-lg">
        <div class="flex items-center ml-4 mt-4 mb-2">
          <i class="pi pi-th-large"></i>
          <div class="text-gray-800 ml-2 text-xl uppercase font-bold">
            Saldo servicios
          </div>
        </div>
        <hr class="ml-4 text-gray-500 mr-4" />
        <BarChart :data="cotStore.dashboard.paymentStatus" />
      </div>
      <div
        class="bg-[#FFCA80] place-content-center h-full shadow rounded-lg pb-2 flex flex-col"
      >
        <div class="flex text-white items-center ml-4 mt-4 mb-2">
          <i class="pi pi-th-large"></i>
          <div class="ml-2 text-xl uppercase font-bold">Informes</div>
        </div>
        <hr class="ml-4 text-white mr-4" />
        <div class="flex justify-center">
          <DonutChart :data="cotStore.dashboard.reportsStatus" />
        </div>
      </div>
    </div>
  </div>
  <!-- Component Container -->
</template>
