import axios from "axios";

const MAX_TRIES = 3;
const RETRY_DELAY = 2000;

/**
 * Se encarga de obtener información de listas de Joget.
 * @param {string} API - Api de lista que se desea obtener.
 * @returns {Array<string>} Resultados de consulta.
 */
export const getJogetApi = async (API) => {
  let url = import.meta.env.VITE_JOGET_BASE_URL + API;

  for (let i = 0; i < MAX_TRIES; i++) {
    try {
      const response = await axios.get(url, {
        headers: {
          accept: "application/json",
          api_id: import.meta.env.VITE_API_ID,
          api_key: import.meta.env.VITE_API_KEY,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response.status === 429) {
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        continue;
      }
      throw error;
    }
  }
  throw new Error("Max tries reached");
};

/**
 * Se encarga de obtener información de listas de Joget.
 * @param {string} API - Api.
 * @param {string} data - Payload.
 * @returns {any} Resultados de consulta.
 */
export const updateJogetApi = async (API, data) => {
  const url = import.meta.env.VITE_JOGET_BASE_URL + API;

  const response = await axios
    .put(url, data, {
      headers: {
        accept: "application/json",
        api_id: import.meta.env.VITE_API_ID,
        api_key: import.meta.env.VITE_API_KEY,
        "Content-Type": "application/json",
      },
    })
    .catch((error) => console.log(error));

  return response;
};

/**
 * Se encarga de crear un item de un formulario de Joget.
 *
 * @param {string} API - Api.
 * @param {string} data - Payload.
 * @returns {any} Resultados de consulta.
 */
export const createJogetApi = async (API, data) => {
  const url = import.meta.env.VITE_JOGET_BASE_URL + API;

  const response = await axios
    .post(url, data, {
      headers: {
        accept: "application/json",
        api_id: import.meta.env.VITE_API_ID,
        api_key: import.meta.env.VITE_API_KEY,
        "Content-Type": "application/json",
      },
    })
    .catch((error) => console.log(error));

  return response;
};

export const getInvoicesAlegraById = async (id) => {
  const url =
    import.meta.env.VITE_ALEGRA_BASE_URL +
    import.meta.env.VITE_ALEGRA_INVOICES_API +
    id;

  for (let i = 0; i < MAX_TRIES; i++) {
    try {
      const response = await axios.get(url, {
        headers: {
          accept: "application/json",
          authorization: import.meta.env.VITE_ALEGRA_API_KEY,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response.status == 404) return null;
      if (error.response.status === 429) {
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        continue;
      }
      throw error;
    }
  }
};
